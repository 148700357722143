<template>
  <div class="foot noSel">
    <div class="footContact hidden-xs-only flex a_c j_s">
      <ul>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/08/64589c5ded63c.png"
            alt=""
          />
          <div>master@sumeils.com</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/08/64589c5cbfe8a.png"
            alt=""
          />
          <div>025-86667555</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/08/64589c5ad8e44.png"
            alt=""
          />
          <div>江苏省南京市江宁区双龙大道1708号4栋5楼</div>
        </li>
      </ul>
      <div class="footCode">
        <img
          src="https://static.5t1800.com/s3/2023/08/28/64ec6034769cd.png"
          alt=""
        />
        <img
          src="https://static.5t1800.com/s3/2023/08/28/64ec60825d21e.png"
          alt=""
        />
      </div>
    </div>
    <div class="footContactPhone hidden-sm-and-up">
      <ul>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/08/64589c5ded63c.png"
            alt=""
          />
          <div>master@sumeils.com</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/08/64589c5cbfe8a.png"
            alt=""
          />
          <div>025-86667555</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/08/64589c5ad8e44.png"
            alt=""
          />
          <div>江苏省南京市江宁区双龙大道1708号4栋5楼</div>
        </li>
      </ul>
      <div class="footCode flex a_c j_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/64589cfea200d.png"
          alt=""
        />
        <img
          src="https://static.5t1800.com/s3/2023/05/08/64589cfea200d.png"
          alt=""
        />
      </div>
    </div>
    <div style="height:2px;background:white;"></div>
    <div class="box hidden-xs-only">
      <ul class="flex j_c a_c">
        <li @click="routerTo('home', 0)">首页</li>
        <li @click="routerTo('brandHistory', 1)">品牌历程</li>
        <li @click="routerTo('skinManagement', 2)">皮肤管理</li>
        <li @click="routerTo('homeProducts', 3)">居家产品</li>
        <li @click="routerTo('franchiseCooperation', 4)">加盟合作</li>
      </ul>
      <div class="record flex j_c a_c">
        <div class="flex a_c j_c">
          <span
            >Copyright：南京乾行信息技术有限公司 ALL RIGHT RESERVED
            备案号：</span
          >
          <a
            style="padding: 0 10px 0 10px"
            href="https://beian.miit.gov.cn/"
            class="icp"
            target="_blank"
            >苏ICP备2021029540号-2</a
          >
        </div>
      </div>
    </div>
    <!-- H5 -->
    <div class="boxH5 hidden-sm-and-up">
      <div class="record">
        <div class="">
          <div>Copyright：南京乾行信息技术有限公司 ALL RIGHT RESERVED</div>
          <div>
            备案号：<a
              style="padding: 0 10px 0 10px"
              href="https://beian.miit.gov.cn/"
              class="icp"
              target="_blank"
              >苏ICP备2021029540号-2</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    msg: String,
  },
  components: {},
  methods: {
    //跳转至免责说明
    toDisclaimer() {
      this.$store.commit("savePersonalIndex", 4);
      this.routerTo("personal");
    },
    //跳转至用户规范
    toUserSpecil() {
      this.routerTo("specil");
    },
    //跳转至官方微博
    toMicro() {
      window.open(
        "https://weibo.com/u/7457296330?refer_flag=1001030103_",
        "_blank"
      );
      // window.open("www.baidu.com");
    },
    //跳转至关于我们
    toAbout() {
      this.$store.commit("saveTypeIndex", 3);
      this.routerTo("brandIntroduction");
    },
    //二级路由跳转
    routerTo(value, i) {
      this.$store.commit("saveTypeIndex", i);
      this.$router.push({ name: value });
    },
    //跳转到备案信息
    linkToICP() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
  },
};
</script>

<style scoped>
.foot {
  background: #000000;
}
.foot .box {
  max-width: 1200px;
  height: 160px;
  margin: 0 auto;
}
.foot .boxH5 {
  padding: 30px 0;
  margin: 0 auto;
}
.foot .box ul {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8d8d8d;
  padding: 30px 0;
  cursor: pointer;
}
.foot .box ul li {
  cursor: pointer;
  border-right: 1px solid #8d8d8d;
  padding: 0 5px;
}
.foot .box ul li:last-child {
  cursor: pointer;
  border-right: none;
  padding: 0 5px;
}
.foot .box ul span {
  margin-left: 10px;
}
.record {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8d8d8d;
  text-align: center;
  flex-wrap: wrap;
}
.icp {
  cursor: pointer;
  color: #8d8d8d;
}
.icp:hover {
  text-decoration: underline;
}

.footContact {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 200;
  color: #8d8d8d;
  padding: 30px 0;
}
.footContact li img {
  width: 30px;
  margin-right: 20px;
}
.footContact li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footContact .footCode img {
  width: 250px;
  height: 124px;
  margin-left: 60px;
}


.footContactPhone {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 200;
  color: #8d8d8d;
  padding: 30px 16px;

}
.footContactPhone li img {
  width: 30px;
  margin-right: 20px;
}
.footContactPhone li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footContactPhone .footCode img {
  width: 250px;
  height: 124px;
  margin-right: 10px;
}
</style>
